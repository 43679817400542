import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="grid h-screen place-items-center bg-purple-dark">
        <div>
          <h1 className="text-6xl font-bold text-grey">Conji Quest</h1>
          <h3 className="text-3xl text-grey">Coming soon...</h3>
        </div>
      </div>
    </div>
  );
}

export default App;
